<template>
  <div class="">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>租户查询</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/addition' }"
        >添加租户</el-breadcrumb-item
      >
    </el-breadcrumb>
    <div id="user-search" class="search">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <h3>租户查询</h3>
        <el-form-item label="租户编号">
          <el-input
            id="user-input"
            placeholder="请输入租户编号"
            v-model="form.type"
          ></el-input>
        </el-form-item>
        <el-form-item label="租户名称">
          <el-input
            id="user-input"
            v-model="form.user"
            placeholder="请输入租户店名"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input
            id="user-input"
            v-model="form.name"
            placeholder="联系人"
          ></el-input>
        </el-form-item>
        <el-form-item class="user-btn">
          <el-button class="inquire" type="primary" @click="onSubmit"
            ><i class="el-icon-search"></i>查询</el-button
          >
          <el-button class="reset" @click="reset"
            >重置</el-button
          > </el-form-item
        ><br />
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <span style="margin-right: 30px">状&nbsp;态</span>
        <el-select placeholder="请选择菜单状态" v-model="checkAll">
          <el-option label="全部" value="1">全部</el-option>
        </el-select>
      </el-form>
    </div>
    <div class="table">
      <div style="text-align: left">
        <router-link to="/addition">
          <el-button id="user-newbtn" type="text" icon="el-icon-plus"
            >新建</el-button
          >
        </router-link>
      </div>
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column
          prop="tenantNum"
          label="租户编号"
          width="150px"
        ></el-table-column>
        <el-table-column
          prop="tenantName"
          label="租户名称"
          width="180px"
        ></el-table-column>
        <el-table-column
          prop="tenantContacts"
          label="联系人"
          width="160px"
        ></el-table-column>
        <el-table-column prop="" label="地址" width="450px">
          <template slot-scope="scope">
            {{ scope.row.tenantProvince }}-{{ scope.row.tenantCity }}-{{
              scope.row.tenantArea
            }}-{{ scope.row.tenantStreet }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <!-- <router-link :to="{path:'/parti',query:{id:}}"> -->

          <!-- <el-button type="text" size="small" icon="el-icon-edit">查看</el-button> -->
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row.id)"
              type="text"
              size="small"
              >查看</el-button
            >
          </template>
          <!-- </router-link> -->
        </el-table-column>
      </el-table>
      <div class="block">
        <div class="pagination">
          <el-pagination
            layout="total, prev, pager, next"
            background
            :total="Number(this.page.totalCount)"
            :page-size="page.pageSize"
            @current-change="currentChange"
            :current-page.sync="page.pageIndex"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../http/index.js";
export default {
  inject: ["reload"],
  data() {
    return {
      value1: "",
      checkAll: "全部",
      form: {
        name: "",
        type: "",
        user: "",
      },
      forms: {
        name: "",
        type: "",
        user: "",
      },
      tableData: [],
      page: [],
    };
  },
  mounted() {
    this.getData();
    // this.reload();
  },
  updated() {},
  methods: {
    getData() {
      http({
        url: "/admin/tenant/list",
        method: "GET",
        params: {
          pageIndex: this.page.pageIndex,
        },
      })
        .then((res) => {
          // console.log(res);
          this.tableData = res.data.data;
          this.page = res.data.page;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onSubmit() {
      http({
        url: "/admin/tenant/list",
        method: "GET",
        params: {
          tenantNum: this.form.type,
          tenantName: this.form.user,
          tenantContacts: this.form.name,
        },
      })
        .then((res) => {
          // console.log(res);
          this.tableData = res.data.data;
          this.page = res.data.page;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    reset() {
      this.reload();
    },
    handleDelete(e, i) {
      this.tableData.splice(i, 1);
    },
    // 点下一页刷新
    currentChange(e) {
      this.page.pageIndex = e;
      this.getData();
    },
    handleClick(id) {
      this.$router.push({ path: "/parti", query: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin: 20px 20px 20px;
  background: #fff;
  padding: 20px;
  text-align: left;
}

.table {
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background: #fff;
}
.el-input {
  width: 18vw;
}
#user-input {
  width: 18vw;
  height: 40px;
}
.el-select {
  width: 18vw;
}
.el-input--suffix {
  width: 18vw;
}
.el-input__inner {
  width: 18vw;

  height: 40px !important;
}
.pagination {
  float: right;
}
.demo-form-inline {
  height: 20vh;
}
.el-input__inner {
  width: 17vw;
  height: 3vh;
}
.el-form-item__content {
  position: relative;
  .el-ton {
    position: absolute;
    width: 5vw;
    height: 4vh;
    text-align: center;
  }
  .reset {
    top: 50px;
    left: -10px;
    line-height: 0px;
  }
  .inquire {
    background-color: #165dff;
    line-height: 10px;
  }
}
.el-range-editor.el-range-editor {
  width: 18vw;
}
.el-icon-search {
  line-height: 1px;
}
.el-ton--primary {
  background-color: #165dff;
}
.el-form-item {
  margin-top: 0px;
}
.el-breadcrumb {
  position: relative;
  top: 10px;
  left: 20px;
}
.el-range__icon {
  margin-top: -10px;
}
.el-range-separator {
  margin-top: -10px !important;
}

.el-icon-arrow-up {
  position: relative;
  left: -45px;
}
.user-btn {
  position: relative;
  left: 4%;
}
#user-newbtn {
  border: 1px solid;
  background-color: #165dff;
  color: white;
  margin-bottom: 30px;
  width: 5vw;
}
.el-button--default {
  width: 84px;
  height: 36px;
}
</style>
