<template>
  <div style="position: relative; top: -25px">
    <el-form ref="form" :model="form" label-width="120px">
      <el-select
        v-model="form.province"
        placeholder="请选择省"
        @change="changePro"
      >
        <el-option
          v-for="item in addressData"
          :key="item.code"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form.city"
        placeholder="请选择市"
        @change="changeCity"
      >
        <el-option
          v-for="item in cityData"
          :key="item.code"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form.district"
        placeholder="请选择区"
        @change="changeArea"
      >
        <el-option
          v-for="item in areaData"
          :key="item.code"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </el-form>
  </div>
</template>
<script>
import address from "../address.json"; //全国省市区街道数据
export default {
  data() {
    return {
      //  省数据
      addressData: [],
      //  市数据
      cityData: [],
      // 区数据
      areaData: [],
      // 街道数据
      form: {
        // 省
        province: "",
        // 市
        city: "",
        // 区
        district: "",
      },
    };
  },
  created() {
    // 省份数据初始化
    this.addressData = address;
  },
  methods: {
    // 省份更改
    changePro(e) {
      // 从省中过滤出市的数据
      this.cityData = this.addressData.filter((item) => {
        return item.name == e;
      })[0].children;
      // 省发生改变的时候 清空输入框市区街道的内容
      this.form.district = "";
      this.form.city = "";
      // 省发生更改时 该表空区街道数据的内容
      this.areaData = [];
      this.$emit("provinces", this.form.province);
    },
    // 市更改
    changeCity(e) {
      // 获取到区的数据
      this.areaData = this.cityData.filter(
        (item) => item.name == e
      )[0].children;
      // 清空数据后面对应数组的数据
      this.form.district = "";
      this.$emit("city", this.form.city);
    },
    // 区更改
    changeArea(e) {
      let temp = this.areaData.filter((item) => item.name == e);
      // 获取到区的code码
      this.form.regionalNumber = temp[0].code;
      // 获取到街道的数据
      this.jdData = this.areaData.filter((item) => item.name == e)[0].children;
      this.$emit("district", this.form.district);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  .el-select {
    input {
      width: 100px;
    }
  }
}
.el-form {
  position: absolute;
  top: -2px;
  left: 44px;
  width: 800px;
}
.el-icon-arrow-up {
  display: none;
}
.el-select {
  margin-left: 20px;
}
.el-input--suffix {
  .el-input__suffix {
    .el-input__suffix-inner {
      position: relative;
      left: -42px;
    }
  }
}
</style>
