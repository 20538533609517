<template>
  <div class="sort">
    <div class="limits">
      <el-dialog title="新建权限名称" :visible.sync="sortDialog">
        <el-form :model="sortFrom">
          <el-form-item label="权限名称" :label-width="formLabelWidth">
            <el-input
              v-model="sortFrom.rightCategoryName"
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="sortDialog = false">取 消</el-button>
          <el-button type="primary" @click="rightCategoryEnsure"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <div class="limits">
      <el-dialog title="修改权限名称" :visible.sync="sortDialogPut">
        <el-form :model="sortFrom">
          <el-form-item label="权限名称" :label-width="formLabelWidth">
            <el-input
              v-model="sortFrom.rightCategoryName"
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="sortDialogPut = false">取 消</el-button>
          <el-button type="primary" @click="changePermission()"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <div class="new-sort">
      <el-button
        @click="
          (sortDialog = true),
            ((sortFrom.rightCategoryName = ''), (sortFrom.rightCategoryId = ''))
        "
        type="primary"
        icon="el-icon-plus"
        >新建</el-button
      >
    </div>
    <div class="sort-list">
      <div
        class="authority"
        v-for="(auth, index) in categoryList"
        :key="index + 'sort'"
      >
        <div class="authority-title">{{ auth.rightCategoryName }}</div>
        <div class="authority-operation">
          <div
            @click="
              (sortDialogPut = true),
                ((sortFrom.rightCategoryId = auth.id),
                (sortFrom.rightCategoryName = auth.rightCategoryName))
            "
          >
            修改
          </div>
          <div @click="rightCategoryDel(auth.id)">删除</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http/index.js";

export default {
  data() {
    return {
      categoryList: [],
      sortDialog: false,
      sortDialogPut: false,
      sortFrom: {
        rightCategoryName: "",
        rightCategoryId: "",
      },
      formLabelWidth: "120px",
    };
  },
  mounted() {
    this.rightCategory();
  },
  methods: {
    // 列表
    rightCategory() {
      http({
        url: "/admin/rightCategory/list",
        method: "GET",
      }).then((res) => {
        this.categoryList = res.data.data;
        console.log(this.categoryList);
      });
    },
    // 新建
    rightCategoryEnsure() {
      http({
        url: "/admin/rightCategory/",
        method: "POST",
        data: {
          rightCategoryName: this.sortFrom.rightCategoryName,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.sortDialog = false;
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.rightCategory();
        }
      });
    },
    // 修改
    changePermission() {
      http({
        url: "/admin/rightCategory/" + this.sortFrom.rightCategoryId,
        method: "PUT",
        data: {
          rightCategoryName: this.sortFrom.rightCategoryName,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.sortDialogPut = false;
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.rightCategory();
        }
      });
    },
    rightCategoryDel(id) {
      this.$confirm("是否删除该权限名称, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          http({
            url: "/admin/rightCategory/" + id,
            method: "DELETE",
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: res.data.msg,
              });
              this.rightCategory();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scope>
.sort {
  background-color: #fff;
  margin-top: 10px;
  width: 98%;
  margin-left: 1%;
  height: 850px;
  border-radius: 5px;
  .new-sort {
    margin-bottom: 20px;
    .el-button {
      margin-top: 20px;
      margin-left: 40px;
      
    }
  }
  .sort-list {
    width: 300px;
    height: 300px;
    margin-left: 100px;
    .authority {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      .authority-title {
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 1px;
      }
      .authority-operation {
        width: 80px;
        display: flex;
        justify-content: space-around;
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        div:nth-child(1) {
          letter-spacing: 1px;
          &:hover {
            cursor: pointer;
            color: #67c23a;
          }
        }
        div:nth-child(2) {
          letter-spacing: 1px;
          &:hover {
            cursor: pointer;
            color: #f56c6c;
          }
        }
      }
    }
  }
}
.limits {
  .el-dialog {
    width: 500px;
  }
}
</style>
