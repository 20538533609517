<template>
  <div id="app">
    <el-container>
      <el-header v-if="ifrouter">
        <div class="app-header">
          <!-- <div>美小帮后台</div> -->
        </div>
      </el-header>
      <el-container>
        <el-aside
          width="200px"
          style="height: calc(100vh - 60px)"
          v-if="ifrouter"
        >
          <el-row class="tac">
            <el-col :span="24">
              <el-menu
                router
                default-active="1"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                background-color="#FFFFFF"
                text-color="#4E5969"
                active-text-color="#165DFF"
              >
                <el-submenu
                  v-for="(navs, index) in nav"
                  :key="index"
                  :index="navs.id"
                >
                  <template slot="title">
                    <i :class="navs.icon"></i>
                    <span>{{ navs.name }}</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item
                      v-for="(navlist, index) in navs.list"
                      :key="index"
                      :index="navlist.url"
                      >{{ navlist.name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
          <div style="position: absolute; top: 800px; left: 50px">
            <el-button @click="quit" type="danger">退出登录</el-button>
          </div>
        </el-aside>
        <el-main :style="!ifrouter ? 'left:0;top:0' : ''">
          <router-view v-if="isbool"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import http from "./http/index.js";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isbool: true,
      ifrouter: false,
      activeIndex: "1",
      nav: [
        // {
        //   id: "1",
        //   name: "仪表盘",
        //   icon: "el-icon-s-tools",
        //   list: [
        //     { name: "菜单管理", url: "" },
        //     { name: "用户管理", url: "" },
        //   ],
        // },
        {
          id: "1",
          name: "门店列表",
          icon: "el-icon-user",
          list: [{ name: "查询表格", url: "/user" }],
        },
        {
          id: "2",
          name: "图片管理",
          icon: "el-icon-user",
          list: [{ name: "图片库", url: "/photo" }],
        },
        {
          id: "3",
          name: "产品项目管理",
          icon: "el-icon-goods",
          list: [
            { name: "产品管理", url: "/product" },
            { name: "项目管理", url: "/project" },
          ],
        },
        {
          id: "4",
          name: "权限管理",
          icon: "el-icon-date",
          list: [
            { name: "权限注册", url: "/register" },
            { name: "权限分类管理", url: "/sort" },
          ],
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/") {
        this.ifrouter = false;
        console.log(from);
      } else {
        this.ifrouter = true;
      }
    },
  },
  methods: {
    reload() {
      this.isbool = false;
      this.$nextTick(() => {
        //下次更新循环后加载
        this.isbool = true;
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    quit() {
      localStorage.removeItem("admin");
      localStorage.removeItem("token");
      localStorage.removeItem("satoken");
      this.$router.push("/");
      http({
        url: "admin/superAdmin/logout",
        method: "POST",
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
body {
  margin: 0;
  height: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  height: 100%;
}
#app .el-header {
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
}
.el-menu-vertical-demo {
  border-right: none !important;
}
.el-header,
.el-footer {
  background-color: #ffffff;
  color: #333;
  /* text-align: center; */
  line-height: 60px;
}
.app-header {
  z-index: 1000;
  background: rgb(255, 255, 255);
  box-shadow: 0px 1px 0px rgb(229, 230, 235),
    0px 4px 10px rgba(78, 89, 105, 0.06);
  text-align: center;
  font-size: 20px;
  letter-spacing: 10px;
  font-weight: 600;
  font-stretch: condensed;
}
.el-aside {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  position: absolute;
  top: 60px;
  left: 0;
}
.el-main {
  position: absolute;
  padding: 20px;
  box-sizing: border-box;
  left: 200px;
  top: 60px;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

.el-container .el-main {
  background-color: #e9eef3;
  color: #333;
  /* text-align: center; */
  padding: 0;
}
.el-menu--horizontal > .el-menu-item {
  float: right !important;
}
.el-menu--horizontal > .el-submenu {
  float: right !important;
}

body > .el-container {
  margin-bottom: 40px;
}
.el-breadcrumb {
  width: 300px;
}
/* .el-container{display: flex;width: 100%;height: 100%;flex:1;} */
</style>
