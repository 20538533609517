<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/user">租户查询</a></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/addition' }"
        >添加租户</el-breadcrumb-item
      >
    </el-breadcrumb>
    <div class="addition-title">
      <h4>添加租户</h4>
      <div class="addition-description">
        <div style="height: 500px; width: 400px">
          <el-steps direction="vertical" :active="1">
            <el-step title="步骤 1" description="客户资料"></el-step>
            <el-step title="步骤 2" description="联系人资料"></el-step>
            <el-step title="步骤 3" description="租户时常"></el-step>
          </el-steps>
        </div>
        <div class="addition-content">
          <!-- 步骤一 -->
          <div class="first">
            <div>
              <span>租户名称</span
              ><input
                v-model="valueLessee"
                type="text"
                placeholder="请输入店名"
              />
            </div>
            <div>
              <span>租户编号</span
              ><input
                v-model="valueSerial"
                type="text"
                placeholder="请输入编号"
              />
            </div>
            <div style="position: relative">
              <span>租户地址</span>
              <Region ref="ui"></Region>
            </div>
            <div style="margin-top: 30px">
              <span style="letter-spacing: 14px">街道</span
              ><input
                v-model="valueStreet"
                type="text"
                placeholder="请输入街道名称"
              />
            </div>
          </div>
          <!-- 步骤二 -->
          <div class="second">
            <div>
              <span>联系人</span
              ><input
                v-model="valueLinkman"
                type="text"
                placeholder="请输入联系人姓名"
              />
            </div>
            <div>
              <span>联系人电话</span
              ><input
                v-model="valueLinkmanPhone"
                type="text"
                placeholder="请输入联系人电话"
              />
            </div>
          </div>
          <div class="thirdly">
            <span>开始时间</span
            ><el-date-picker
              value-format="yyyy-MM-dd"
              v-model="valueData1"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <span>结束时间</span
            ><el-date-picker
              value-format="yyyy-MM-dd"
              v-model="valueData2"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <el-button @click="logging" class="reset" type="success">提交</el-button>
    </div>
  </div>
</template>

<script>
import Region from "../components/Region.vue";
import http from "../http/index.js";
export default {
  components: {
    Region,
  },
  data() {
    return {
      // 开始时间
      valueData1: "",
      //   结束时间
      valueData2: "",
      //   租户名称
      valueLessee: "",
      //  租户编号
      valueSerial: "",
      //   街道
      valueStreet: "",
      //   联系人
      valueLinkman: "",
      // 联系人电话
      valueLinkmanPhone: "",
    };
  },
  mounted() {},
  methods: {
    logging() {
      if (
        !this.valueLinkman ||
        !this.valueLessee ||
        !this.valueSerial ||
        !this.valueStreet ||
        !this.valueLinkmanPhone ||
        !this.valueData1 ||
        !this.valueData2
      )
        return this.$message({
          message: "请把信息补齐后再提交",
          type: "error",
        });
      let { city, district, province } = this.$refs["ui"].form;
      http({
        url: "admin/tenant/",
        method: "POST",
        data: {
          startTime: this.valueData1,
          expirationTime: this.valueData2,
          tenantName: this.valueLessee,
          tenantNum: this.valueSerial,
          tenantContacts: this.valueLinkman,
          tenantPhone: this.valueLinkmanPhone,
          tenantStreet: this.valueStreet,
          // 租户省
          tenantProvince: province,
          // 租户市
          tenantCity: city,
          // 租户区
          tenantArea: district,
        },
      })
        .then((res) => {
          console.log(res);
          // 完成操作后 页面刷新
          this.$router.push({ path: "/user" });
          this.$message({
            type: "success",
            message: "添加成功",
          });
        })
        .catch((err) => {
          console.error(err);
        });
      
    },
  },
};
</script>

<style lang="scss" scoped>
.addition-title {
  background-color: #fff;
  height: 85vh;
  width: 80vw;
  margin-left: 2%;
  h4 {
    text-align: left;
    padding-top: 2%;
    padding-left: 2%;
  }
}
.addition-description {
  display: flex;
}
.el-steps--vertical {
  margin-left: 50%;
}
.addition-content {
  float: left;
  width: 600px;
}
.el-input__inner {
  width: 50%;
}
.first {
  margin-top: 4%;
  margin-bottom: 5%;
  text-align: left;
  span {
    font-size: 14px;
    padding-right: 1%;
  }
  input {
    width: 200px;
    height: 30px;
    margin-bottom: 4%;
    border-radius: 8px;
    border: 1px solid #f4f5f7;
    outline: none;
  }
  select {
    width: 100px;
    height: 30px;
    margin-bottom: 4%;
    border-radius: 8px;
    border: 1px solid #f4f5f7;
  }
}
.second {
  margin-bottom: 10%;
  text-align: left;
  span {
    font-size: 14px;
    padding-right: 1%;
  }
  input {
    width: 200px;
    height: 30px;
    margin-bottom: 4%;
    border-radius: 8px;
    border: 1px solid #f4f5f7;
    outline: none;
  }
}
.thirdly {
  text-align: left;
  span {
    font-size: 14px;
    padding-right: 1%;
  }
  input {
    border: 1px solid #f4f5f7;
  }
}
.el-date-editor.el-input {
  width: 150px;
}
.el-input__inner {
  width: 100%;
}
.reset {
  margin-top: 10%;
}
.el-breadcrumb {
  position: relative;
  top: 10px;
  left: 20px;
}
.el-form {
  width: 750px !important;
  .el-select {
    input {
      width: 150px;
    }
  }
}
.el-form {
  width: 750px !important;
  position: absolute;
  top: -24px;
  left: 62px;
}
.el-icon-arrow-up {
  display: none;
}
</style>
