<template>
  <div class="register">
    <div class="register-dialog">
      <el-dialog title="注册权限" :visible.sync="registerNew">
        <el-form :model="registerFrom">
          <el-form-item label="权限名称" :label-width="formLabelWidth">
            <el-input
              v-model="registerFrom.rightName"
              style="width: 350px"
            ></el-input>
          </el-form-item>
          <el-form-item label="权限标识" :label-width="formLabelWidth">
            <el-input
              v-model="registerFrom.rightTag"
              style="width: 350px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="registerNew = false">取 消</el-button>
          <el-button type="primary" @click="confirmRegistration"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <div class="register-dialog">
      <el-dialog title="修改注册权限" :visible.sync="registerPut">
        <el-form :model="registerFrom">
          <el-form-item label="权限名称" :label-width="formLabelWidth">
            <el-input
              v-model="registerFrom.rightName"
              style="width: 350px"
            ></el-input>
          </el-form-item>
          <el-form-item label="权限标识" :label-width="formLabelWidth">
            <el-input
              v-model="registerFrom.rightTag"
              style="width: 350px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="registerPut = false">取 消</el-button>
          <el-button type="primary" @click="putRegistration">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div
      class="register-list"
      v-for="(control, index) in categoryList"
      :key="index + 'a'"
    >
      <div class="title">
        <div class="title-msg">{{ control.rightCategoryName }}</div>
        <div>
          <el-button
            @click="
              (registerNew = true),
                ((registerFrom.rightName = ''),
                (registerFrom.rightTag = ''),
                (registerFrom.categoryId = '')),
                (permissionClassification = control.id),
                rightCategory()
            "
            type="primary"
            icon="el-icon-plus"
            round
            >注册</el-button
          >
        </div>
      </div>
      <el-divider></el-divider>
      <ul class="content">
        <li
          class="content-li"
          v-for="(right, index) in control.jurisdictionArrList"
          :key="index + '1'"
        >
          <div class="li-name">{{ right.rightName }}</div>
          <div class="li-msg">{{ right.rightTag }}</div>
          <div class="li-icon">
            <div>
              <i
                @click="
                  (registerPut = true),
                    (registerFrom.rightName = right.rightName),
                    (registerFrom.rightTag = right.rightTag),
                    (registerFrom.categoryId = right.id)
                "
                class="el-icon-edit-outline"
              ></i>
            </div>
            <div>
              <i class="el-icon-delete" @click="delRight(right.id)"></i>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import http from "@/http/index.js";
export default {
  data() {
    return {
      registerNew: false,
      registerPut: false,
      jurisdictionArrList: [],
      registerFrom: {
        rightName: "",
        rightTag: "",
        categoryId: "",
      },
      categoryList: [],
      formLabelWidth: "70px",
      permissionClassification: "",
    };
  },
  mounted() {
    // this.registerRightList();
    // this.rightCategory();
    Promise.all([this.registerRightList(), this.rightCategory()])
      .then(() => {
        this.categorizeJurisdictionArrList();
      })
      .catch((error) => {
        console.error(error);
        // 错误处理代码
      });
  },
  methods: {
    registerRightList() {
      return http({
        url: "/admin/right/list",
        method: "GET",
        params: {
          pageSize: 500,
        },
      }).then((res) => {
        this.jurisdictionArrList = res.data.data;
        console.log(this.jurisdictionArrList);
        this.categorizeJurisdictionArrList();
      });
    },
    rightCategory() {
      return http({
        url: "/admin/rightCategory/list/?orderBy=" + "id:ASC",
        method: "GET",
      }).then((res) => {
        this.categoryList = res.data.data;
        this.categorizeJurisdictionArrList();
      });
    },
    categorizeJurisdictionArrList() {
      if (!this.jurisdictionArrList || !this.categoryList) {
        return;
      }
      for (let i = 0; i < this.categoryList.length; i++) {
        const category = this.categoryList[i];
        category.jurisdictionArrList = [];
        for (let j = 0; j < this.jurisdictionArrList.length; j++) {
          const right = this.jurisdictionArrList[j];
          if (right.categoryId === category.id) {
            category.jurisdictionArrList.push(right);
          }
        }
      }
      this.$forceUpdate()
    },
    confirmRegistration() {
      http({
        url: "/admin/right/",
        method: "POST",
        data: {
          categoryId: this.permissionClassification,
          rightName: this.registerFrom.rightName,
          rightTag: this.registerFrom.rightTag,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.registerNew = false;
          http({
            url: "/admin/right/list",
            method: "GET",
            params: {
              pageSize: 500,
            },
          }).then((res) => {
            this.jurisdictionArrList = res.data.data;
            this.categoryList.forEach((category) => {
              category.jurisdictionArrList = []; // 清空权限列表
            });
            this.categorizeJurisdictionArrList();

            // 手动触发数据更新
            this.$set(this, "jurisdictionArrList", this.jurisdictionArrList);
            this.categoryList.forEach((category, index) => {
              this.$set(this.categoryList, index, category);
            });
          });
        }
      });
    },
    delRight(id) {
      this.$confirm("是否删除该权限名称, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          http({
            url: "/admin/right/" + id,
            method: "DELETE",
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: res.data.msg,
              });
              http({
                url: "/admin/right/list",
                method: "GET",
                params: {
                  pageSize: 500,
                },
              }).then((res) => {
                this.jurisdictionArrList = res.data.data;
                this.categoryList.forEach((category) => {
                  category.jurisdictionArrList = []; // 清空权限列表
                });
                this.categorizeJurisdictionArrList();

                // 手动触发数据更新
                this.$set(
                  this,
                  "jurisdictionArrList",
                  this.jurisdictionArrList
                );
                this.categoryList.forEach((category, index) => {
                  this.$set(this.categoryList, index, category);
                });
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    putRegistration() {
      http({
        url: "/admin/right/" + this.registerFrom.categoryId,
        method: "PUT",
        data: {
          rightName: this.registerFrom.rightName,
          rightTag: this.registerFrom.rightTag,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.registerPut = false;
          http({
            url: "/admin/right/list",
            method: "GET",
            params: {
              pageSize: 500,
            },
          }).then((res) => {
            this.jurisdictionArrList = res.data.data;
            this.categoryList.forEach((category) => {
              category.jurisdictionArrList = []; // 清空权限列表
            });
            this.categorizeJurisdictionArrList();

            // 手动触发数据更新
            this.$set(this, "jurisdictionArrList", this.jurisdictionArrList);
            this.categoryList.forEach((category, index) => {
              this.$set(this.categoryList, index, category);
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
.register {
  background-color: #fff;
  margin-top: 10px;
  width: 98%;
  margin-left: 1%;
  height: 850px;
  border-radius: 5px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .register-list {
    width: 98%;
    // height: 300px;
    margin-bottom: 30px;
    margin-left: 1%;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      .title-msg {
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
    .el-divider {
      margin: 10px 0 !important;
    }
    .content {
      list-style: none;
      margin: 0px;
      padding: 0px;
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .content-li {
        width: 240px;
        height: 100px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(136, 136, 136, 0.25);
        margin-right: 25px;
        margin-bottom: 20px;
        .li-name {
          padding-top: 10px;
          padding-left: 15px;
          font-weight: 600;
          font-size: 14px;
        }
        .li-msg {
          padding-top: 5px;
          padding-left: 20px;
          font-size: 14px;
        }
        .li-icon {
          display: flex;
          justify-content: flex-end;
          padding-top: 10px;
          text-align: center;
          div:nth-child(1) {
            background-color: #ffefb5;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            margin-right: 10px;
          }
          div:nth-child(2) {
            background-color: #f3cac1;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.register-dialog {
  .el-dialog {
    width: 500px;
    .el-input__suffix {
      left: 180px;
    }
  }
}
</style>
