<template>
  <div class="">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">租户查询</el-breadcrumb-item>
      <el-breadcrumb-item>租户详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div
      id="user-search"
      class="parti-search"
      v-for="(item, index) in rentList[0]"
      :key="index + 'a'"
    >
      <div class="parti-search-one">
        <div>
          租户名称：<span>{{ item.tenantName }}</span>
        </div>
        <div>
          租户编号：<span>{{ item.tenantNum }}</span>
        </div>
        <div>
          生效起止：<span>{{ item.startTime }}至{{ item.expirationTime }}</span>
        </div>
      </div>
      <div class="parti-search-two">
        <div>
          联系人：<span>{{ item.tenantContacts }}</span>
        </div>
        <div>
          手机号码：<span>{{ item.tenantPhone }}</span>
        </div>
        <div>
          租户地址：<span
            >{{ item.tenantProvince }}{{ item.tenantCity }}{{ item.tenantArea
            }}{{ item.tenantStreet }}</span
          >
        </div>
      </div>
      <div>
        <el-button type="primary" @click="dialogFormVisible = true"
          >修改</el-button
        >
        <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
          <el-form class="parti-form-content">
            <div class="parti-form-content-one">
              <div class="parti-form-content-one-left">
                <el-form-item label="租户名称">
                  <el-input
                    class="parti-form-content-input"
                    v-model="item.tenantName"
                  ></el-input>
                </el-form-item>
                <el-form-item label="租户编号">
                  <el-input
                    class="parti-form-content-input"
                    v-model="item.tenantNum"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="parti-form-content-one-right">
                <el-form-item label-width="66px" label="联系人">
                  <el-input
                    class="parti-form-content-input"
                    v-model="item.tenantContacts"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号码">
                  <el-input
                    class="parti-form-content-input"
                    v-model="item.tenantPhone"
                  ></el-input>
                </el-form-item>
              </div>
            </div>

            <div class="parti-form-content-two">
              <el-form-item
                label="租户地址"
                label-width="70px"
                class="parti-rog"
              >
                <div class="parti-region">
                  <Region
                    ref="ui"
                    @provinces="fn1"
                    @city="fn2"
                    @district="fn3"
                  ></Region>
                </div>
              </el-form-item>
              <el-form-item
                label="街道地址"
                label-width="70px"
                class="parti-right"
              >
                <el-input
                  class="parti-form-content-input-pro"
                  v-model="item.tenantStreet"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="生效起止"
                label-width="70px"
                style="position: relative; top: 0px"
                class="particu-title"
              >
                <div class="thirdly">
                  <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="item.startTime"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker
                  ><el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="item.expirationTime"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="five">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="parti-table">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="员工管理" name="first">员工管理</el-tab-pane>
        <el-tab-pane label="客户管理" name="second">客户管理</el-tab-pane>
        <el-tab-pane label="项目管理" name="third">项目管理</el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Region from "../components/Region.vue";
import http from "../http/index.js";
export default {
  components: {
    Region,
  },
  data() {
    return {
      activeName: "first",
      rentList: [
        {
          li: {},
        },
      ],
      valueid: this.$route.query.id,
      //  form: {
      //     name: '',
      //     region: '',
      //     delivery: false,
      //     type: [],
      //     resource: '',
      //   },
      formLabelWidth: "150px",
      dialogFormVisible: false,
    };
  },
  mounted() {
    this.mop();
    console.log(this.rentList[0]);
  },
  methods: {
    mop() {
      http({
        url: "admin/tenant/" + this.valueid,
        method: "GET",
      })
        .then((res) => {
          // console.log(res);
          this.rentList[0].li = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    five() {
      this.dialogFormVisible = false;
      http({
        url: "admin/tenant/" + this.valueid,
        method: "PUT",
        data: {
          //店名
          tenantName: this.rentList[0].li.tenantName,
          //  省份
          tenantProvince: this.tenantProvince,
          // 市
          tenantCity: this.tenantCity,
          // 区
          tenantArea: this.tenantArea,
          // 街道
          tenantStreet: this.rentList[0].li.tenantStreet,
          // 联系人姓名
          tenantContacts: this.rentList[0].li.tenantContacts,
          // 联系人电话
          tenantPhone: this.rentList[0].li.tenantPhone,
          // 开始时间
          startTime: this.rentList[0].li.startTime,
          // 结束时间
          expirationTime: this.rentList[0].li.expirationTime,
        },
      })
        .then((res) => {
          console.log(res);
          this.mop();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fn1(value) {
      this.tenantProvince = value;
    },
    fn2(value) {
      this.tenantCity = value;
    },
    fn3(value) {
      this.tenantArea = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.parti-search {
  margin: 20px 20px 20px;
  background: #fff;
  padding: 20px;
  text-align: left;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .parti-search-one {
    div {
      margin-bottom: 10%;
      span {
        font-weight: 600;
      }
    }
  }
  .parti-search-two {
    div {
      margin-bottom: 10%;
      span {
        font-weight: 600;
      }
    }
  }
}

.parti-table {
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 20px;
  background: #fff;
}
.el-button--primary {
  width: 7vw;
}
.el-breadcrumb {
  position: relative;
  top: 10px;
  left: 20px;
}
// .el-form-item{
//   margin: 0;
//   padding: 0;
// }
.parti-form-content-one {
  display: flex;
  .parti-form-content-one-left {
    flex: 1;
  }
  .parti-form-content-one-right {
    flex: 1;
  }
}
.parti-form-content-input {
  width: 300px;
}
.parti-form-content-input-pro {
  width: 350px;
}
</style>
