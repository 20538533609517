<template>
  <div class="photo">
    <div class="library">
      <el-dialog title="上传图片" :visible.sync="material">
        <el-form :model="projectFrom">
          <el-form-item label="项目图片" :label-width="formLabelWidth">
            <OssPage v-model="projectFrom.userImg"></OssPage>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="material = false">取 消</el-button>
          <el-button type="primary" @click="materialManagement()"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <div class="preview">
      <el-dialog
        :visible.sync="previewImg"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <div style="text-align: center">
          <img width="50%" :src="projectFrom.userImg" alt="" />
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            style="width: 10%"
            type="primary"
            @click="copyUrl(projectFrom.userImg)"
            >复制链接</el-button
          >
          <el-button
            style="width: 10%"
            type="danger"
            @click="deletingImages(projectFrom.id)"
            >删除</el-button
          >
          <el-button style="width: 10%" @click="previewImg = false"
            >关闭</el-button
          >
        </div>
      </el-dialog>
    </div>
    <div class="new-photo">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="(material = true), (projectFrom.userImg = '')"
        >上传</el-button
      >
    </div>
    <ul class="photoList">
      <li class="photo-li" v-for="(img, index) in libraryList" :key="index">
        <img :src="img.url" alt="" />
        <div class="li-operation">
          <div
            @click="
              ((projectFrom.userImg = img.url), (projectFrom.id = img.id)),
                (previewImg = true)
            "
            style="font-size: 17px; margin-left: 10%; width: 35%"
          >
            预览
          </div>
          <div style="width: 45%; margin-left: 7%">
            <i
              @click="copyUrl(img.url)"
              class="el-icon-link"
              style="margin-right: 10%"
            ></i>
            <i @click="deletingImages(img.id)" class="el-icon-delete"></i>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import OssPage from "@/components/OssPage.vue";
import http from "@/http/index.js";
export default {
  components: {
    OssPage,
  },
  data() {
    return {
      libraryList: [],
      material: false,
      previewImg: false,
      formLabelWidth: "120px",
      // 上传图片
      projectFrom: {
        userImg: "",
        id: "",
      },
      photoId: "",
    };
  },
  mounted() {
    this.materialLibraryList();
  },
  methods: {
    async materialLibraryList() {
      const res = await http({
        url: "/admin/officialMaterialLibrary/list/",
        method: "GET",
      })
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(res);
      this.libraryList = res;
    },
    // 点击复制图片链接
    copyUrl(val) {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");

      this.$message({
        type: "success",
        message: "复制成功",
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    materialManagement() {
      http({
        url: "/admin/officialMaterialLibrary/",
        method: "POST",
        data: {
          url: this.projectFrom.userImg,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.material = false;
          this.materialLibraryList();
        }
      });
    },
    deletingImages(id) {
      http({
        url: "/admin/officialMaterialLibrary/" + id,
        method: "DELETE",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.materialLibraryList();
          this.previewImg = false;
        }
      });
    },
    preview() {},
  },
};
</script>

<style lang="scss">
.photo {
  background-color: #fff;
  margin-top: 10px;
  width: 98%;
  margin-left: 1%;
  height: 850px;
  .new-photo {
    .el-button {
      margin-top: 20px;
      margin-left: 40px;
    }
  }
  .photoList {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 660px;
    .photo-li {
      margin: 20px;
      width: 180px;
      height: 180px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .li-operation {
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 20%;
        position: absolute;
        bottom: 0px;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #fff;
        font-size: 22px;
        div:nth-child(1) {
          &:hover {
            cursor: pointer;
            color: #67c23a;
          }
        }
        .el-icon-link:hover {
          cursor: pointer;
          color: #52b7f5;
        }
        .el-icon-delete:hover {
          cursor: pointer;
          color: #f56c6c;
        }
      }
    }
  }
  .library {
    .el-dialog {
      width: 500px;
    }
    .el-dialog__body {
      .el-form {
        .el-form-item {
          .el-form-item__content {
            .el-upload-list {
              width: 100px;
              position: absolute;
              left: 220px;
              top: -40px;
              .el-upload-list__item {
                .el-upload-list__item-name {
                  display: none;
                }
              }
            }
            .el-input {
              width: 300px;
            }
          }
        }
      }
    }
  }
  .preview {
    .el-dialog {
      margin-top: 2vh !important;
    }
  }
}
</style>
