<template>
  <div class="project">
    <div class="addproject">
      <el-dialog title="新建项目" :visible.sync="dialogproject">
        <el-form :model="projectFrom">
          <el-form-item label="项目图片" :label-width="formLabelWidth">
            <OssPage v-model="projectFrom.projectPicture"></OssPage>
          </el-form-item>
          <el-form-item label="项目名称" :label-width="formLabelWidth">
            <el-input
              v-model="projectFrom.projectName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目价格" :label-width="formLabelWidth">
            <el-input
              v-model="projectFrom.projectPrice"
              autocomplete="off"
              @blur="handleBlur"
              onkeypress="return (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode === 46"
              placeholder="请输入数字"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogproject = false">取 消</el-button>
          <el-button type="primary" @click="newproject()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addproject">
      <el-dialog title="修改项目" :visible.sync="amendproject">
        <el-form :model="projectFrom">
          <el-form-item label="项目图片" :label-width="formLabelWidth">
            <OssPage v-model="projectFrom.projectPicture"></OssPage>
          </el-form-item>
          <el-form-item label="项目名称" :label-width="formLabelWidth">
            <el-input
              v-model="projectFrom.projectName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目价格" :label-width="formLabelWidth">
            <el-input
              v-model="projectFrom.projectPrice"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="delproject()">删除</el-button>
          <el-button @click="amendproject = false">取 消</el-button>
          <el-button type="primary" @click="putproject()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="new-project">
      <el-button type="primary" icon="el-icon-plus" @click="shareproject"
        >新建</el-button
      >
    </div>
    <ul class="projectList">
      <li
        class="project-li"
        v-for="(project, index) in projectArrList"
        :key="index + 'project'"
      >
        <img :src="project.projectPicture" alt="" />
        <div class="li-msg">
          <div class="msg-name">{{ project.projectName }}</div>
          <div class="msg-content">
            <div>￥{{ project.projectPrice }}</div>
            <div>
              <button @click="modificationproject(project.id)">修改</button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="paging">
      <el-pagination
        background
        @current-change="currentChange"
        :current-page.sync="page.pageIndex"
        :page-size="page.pageSize"
        layout="total, prev, pager, next"
        :total="Number(this.page.totalCount)"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import OssPage from "@/components/OssPage.vue";
import http from "@/http/index.js";
export default {
  components: {
    OssPage,
  },
  data() {
    return {
      dialogproject: false,
      projectFrom: {
        projectName: "",
        projectPrice: "",
        projectPicture: "",
      },
      formLabelWidth: "120px",
      projectArrList: [],
      amendproject: false,
      projectId: "",
      page: [],
    };
  },
  mounted() {
    this.projectList();
  },
  methods: {
    shareproject() {
      this.dialogproject = true;
      this.projectFrom = {
        projectName: "",
        projectPrice: "",
        projectPicture: "",
      };
    },
    newproject() {
      http({
        url: "/admin/shareProject/",
        method: "POST",
        data: {
          projectPicture: this.projectFrom.projectPicture,
          projectName: this.projectFrom.projectName,
          projectPrice: this.projectFrom.projectPrice,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.dialogproject = false;
          this.projectList();
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        }
      });
    },
    projectList() {
      http({
        url: "/admin/shareProject/list",
        method: "GET",
        params: {
          pageIndex: this.page.pageIndex,
        },
      }).then((res) => {
        this.projectArrList = res.data.data;
        this.page = res.data.page;
      });
    },
    currentChange(e) {
      this.page.pageIndex = e;
      this.projectList();
    },
    modificationproject(id) {
      this.amendproject = true;
      this.projectId = id;
      http({
        url: "/admin/shareProject/" + id,
        method: "GET",
      }).then((res) => {
        console.log(res);
        this.projectFrom = {
          projectName: res.data.data.projectName,
          projectPrice: res.data.data.projectPrice,
          projectPicture: res.data.data.projectPicture,
        };
      });
    },
    putproject() {
      http({
        url: "/admin/shareProject/" + this.projectId,
        method: "PUT",
        data: {
          projectPicture: this.projectFrom.projectPicture,
          projectName: this.projectFrom.projectName,
          projectPrice: this.projectFrom.projectPrice,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.amendproject = false;
          this.projectList();
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        }
      });
    },
    delproject() {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          http({
            url: "/admin/shareProject/" + this.projectId,
            method: "DELETE",
          }).then((res) => {
            if (res.data.code == 0) {
              this.amendproject = false;
              this.projectList();
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleBlur() {
      let price = parseFloat(this.projectFrom.projectPrice);
      if (isNaN(price)) {
        this.projectFrom.projectPrice = "";
      } else {
        if (price === parseInt(price)) {
          // 如果没有小数位，自动补上两个00
          this.projectFrom.projectPrice = price.toFixed(2);
        } else if (price.toFixed(1) === this.projectFrom.projectPrice) {
          // 如果小数位只有一个数字，则在后面补一个0
          this.projectFrom.projectPrice = price.toFixed(2);
        } else {
          this.projectFrom.projectPrice = price.toFixed(2);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.project {
  background-color: #fff;
  margin-top: 10px;
  width: 98%;
  margin-left: 1%;
  height: 850px;
  border-radius: 5px;
  .new-project {
    .el-button {
      margin-top: 20px;
      margin-left: 40px;
    }
  }
  .projectList::-webkit-scrollbar {
    display: none;
  }
  .projectList {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 660px;
    .project-li {
      margin: 20px;
      width: 180px;
      height: 180px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      position: relative;
      &:hover {
        box-shadow: 0px 0px 6px rgb(163, 165, 166);
        transition: 0.3s;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      .li-msg {
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0px;
        border-radius: 0px 0px 10px 10px;
        .msg-name {
          font-weight: 600;
          padding-top: 5px;
          padding-left: 10px;
        }
        .msg-content {
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 14px;
          padding-top: 5px;
          div:nth-child(1) {
            font-weight: 600;
          }
          div:nth-child(2) {
            button {
              width: 50px;
              height: 25px;
              border: none;
              background-color: #409eff;
              color: #fff;
              border-radius: 5px;
              &:hover {
                background-color: #66b1ff;
                transition: 0.5s;
              }
              &:active {
                background-color: #3a8ee6;
              }
            }
          }
        }
      }
    }
  }
  .paging {
    position: fixed;
    bottom: 30px;
  }
  //   弹窗
  .addproject {
    .el-dialog {
      width: 500px;
    }
    .el-dialog__body {
      .el-form {
        .el-form-item {
          .el-form-item__content {
            .el-upload-list {
              width: 100px;
              position: absolute;
              left: 220px;
              top: -40px;
              .el-upload-list__item {
                .el-upload-list__item-name {
                  display: none;
                }
              }
            }
            .el-input {
              width: 300px;
            }
          }
        }
      }
    }
  }
}
</style>
