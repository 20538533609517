import axios from "axios";
import router from '@/router'
import { Message } from 'element-ui';

export default function(config) {
    const instance = axios.create({
        baseURL:"https://store.shiguangyimei.com/backstage",
        timeout: 5000, // 请求超时时间
        headers:{
          'Content-Type':'application/json',
          'authentication':localStorage.getItem("satoken")
        }
    })
    instance.interceptors.response.use(
        response => response,
        error => {
          const { status, data: { msg } } = error.response;
          if (status === 401) {
            Message.error(msg);
            router.replace({ path: "/" });
          }
          return Promise.reject(error);
        }
      );
    return instance(config)
}


  
 