<template>
  <div class="home">
    <div class="home-from">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="age">
          <el-input
            placeholder="请输入用户名"
            v-model.number="ruleForm.age"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            placeholder="请输入密码"
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item class="home-form-item">
          <el-button
            class="home-btn"
            type="primary"
            @click="submitForm('ruleForm')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../http/index.js";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("用户名不能为空"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        age: "",
        admin: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          http({
            url:
              "/admin/superAdmin/doLogin?account=" +
              this.ruleForm.age +
              "&password=" +
              this.ruleForm.pass,
            method: "POST",
            headers: {
              // 在这里添加一个新的 headers 配置，覆盖默认的 satoken 配置
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                localStorage.setItem(
                  "admin",
                  JSON.stringify(res.data.data.admin)
                );
                localStorage.setItem(
                  "token",
                  JSON.stringify(res.data.data.token)
                );
                localStorage.setItem("satoken", res.data.data.token.tokenValue);
                this.$router.push("/user");
              } else {
                this.$message(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss">
.home {
  // text-align: center;
  .home-from {
    width: 500px;
    height: 280px;
    margin: 200px auto;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    background: rgb(255, 255, 255);
    border-radius: 26px;
    .el-form {
      text-align: center;

      .el-form-item {
        .el-form-item__content {
          .el-input {
            width: 300px;
          }
        }
      }
    }
    input {
      width: 300px;
      text-align: center;
      border: none;
      border-bottom: 1px solid #ccc;
      border-radius: 0px;
    }
  }
}
.el-form-item__content {
  margin-left: 0px !important;
}
.home-router {
  float: right;
}
.home-form-item {
  span {
    font-size: 18px;
    color: #fff;
  }
}
.home-btn {
  width: 300px;
  height: 50px;
  background-color: #076fa2;
  color: #fff;
}
.el-form-item__error {
  left: 40% !important;
}
.el-container .el-main {
  background-color: #f7f8fa !important;
}
.el-form-item {
  margin-bottom: 40px;
  margin-top: 30px;
}
.el-input__suffix {
  position: relative;
  left: 230px;
}
</style>
