<template>
  <div class="product">
    <div class="addProduct">
      <el-dialog title="新建产品" :visible.sync="dialogProduct">
        <el-form :model="productFrom">
          <el-form-item label="产品图片" :label-width="formLabelWidth">
            <OssPage v-model="productFrom.productImg"></OssPage>
          </el-form-item>
          <el-form-item label="产品名称" :label-width="formLabelWidth">
            <el-input
              v-model="productFrom.productName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品价格" :label-width="formLabelWidth">
            <el-input
              v-model="productFrom.productPrice"
              autocomplete="off"
              @blur="handleBlur"
              onkeypress="return (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode === 46"
              placeholder="请输入数字"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogProduct = false">取 消</el-button>
          <el-button type="primary" @click="newproduct()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addProduct">
      <el-dialog title="修改产品" :visible.sync="amendProduct">
        <el-form :model="productFrom">
          <el-form-item label="产品图片" :label-width="formLabelWidth">
            <OssPage v-model="productFrom.productImg"></OssPage>
          </el-form-item>
          <el-form-item label="产品名称" :label-width="formLabelWidth">
            <el-input
              v-model="productFrom.productName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品价格" :label-width="formLabelWidth">
            <el-input
              v-model="productFrom.productPrice"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="delproduct()">删除</el-button>
          <el-button @click="amendProduct = false">取 消</el-button>
          <el-button type="primary" @click="putproduct()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="new-product">
      <el-button type="primary" icon="el-icon-plus" @click="shareProduct"
        >新建</el-button
      >
    </div>
    <ul class="productList">
      <li
        class="product-li"
        v-for="(product, index) in productArrList"
        :key="index + 'product'"
      >
        <img :src="product.productImg" alt="" />
        <div class="li-msg">
          <div class="msg-name">{{ product.productName }}</div>
          <div class="msg-content">
            <div>￥{{ product.productPrice }}</div>
            <div>库存:∞</div>
            <div>
              <button @click="modificationProduct(product.id)">修改</button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="paging">
      <el-pagination
        background
        @current-change="currentChange"
        :current-page.sync="page.pageIndex"
        :page-size="page.pageSize"
        layout="total, prev, pager, next"
        :total="Number(this.page.totalCount)"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import OssPage from "@/components/OssPage.vue";
import http from "@/http/index.js";
export default {
  components: {
    OssPage,
  },
  data() {
    return {
      dialogProduct: false,
      productFrom: {
        productName: "",
        productPrice: "",
        productImg: "",
      },
      formLabelWidth: "120px",
      productArrList: [],
      amendProduct: false,
      productId: "",
      page: [],
    };
  },
  mounted() {
    this.productList();
  },
  methods: {
    shareProduct() {
      this.dialogProduct = true;
      this.productFrom = {
        productName: "",
        productPrice: "",
        productImg: "",
      };
    },
    newproduct() {
      http({
        url: "/admin/shareProduct/",
        method: "POST",
        data: {
          productImg: this.productFrom.productImg,
          productName: this.productFrom.productName,
          productPrice: this.productFrom.productPrice,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.dialogProduct = false;
          this.productList();
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        }
      });
    },
    productList() {
      http({
        url: "/admin/shareProduct/list",
        method: "GET",
        params: {
          pageIndex: this.page.pageIndex,
        },
      }).then((res) => {
        this.productArrList = res.data.data;
        this.page = res.data.page;
      });
    },
    currentChange(e) {
      this.page.pageIndex = e;
      this.productList()
    },
    modificationProduct(id) {
      this.amendProduct = true;
      this.productId = id;
      http({
        url: "/admin/shareProduct/" + id,
        method: "GET",
      }).then((res) => {
        console.log(res);
        this.productFrom = {
          productName: res.data.data.productName,
          productPrice: res.data.data.productPrice,
          productImg: res.data.data.productImg,
        };
      });
    },
    putproduct() {
      http({
        url: "/admin/shareProduct/" + this.productId,
        method: "PUT",
        data: {
          productImg: this.productFrom.productImg,
          productName: this.productFrom.productName,
          productPrice: this.productFrom.productPrice,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.amendProduct = false;
          this.productList();
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        }
      });
    },
    delproduct() {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          http({
            url: "/admin/shareProduct/" + this.productId,
            method: "DELETE",
          }).then((res) => {
            if (res.data.code == 0) {
              this.amendProduct = false;
              this.productList();
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleBlur() {
      let price = parseFloat(this.productFrom.productPrice);
      if (isNaN(price)) {
        this.productFrom.productPrice = "";
      } else {
        if (price === parseInt(price)) {
          // 如果没有小数位，自动补上两个00
          this.productFrom.productPrice = price.toFixed(2);
        } else if (price.toFixed(1) === this.productFrom.productPrice) {
          // 如果小数位只有一个数字，则在后面补一个0
          this.productFrom.productPrice = price.toFixed(2);
        } else {
          this.productFrom.productPrice = price.toFixed(2);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.product {
  background-color: #fff;
  margin-top: 10px;
  width: 98%;
  margin-left: 1%;
  height: 850px;
  border-radius: 5px;
  .new-product {
    .el-button {
      margin-top: 20px;
      margin-left: 40px;
    }
  }
  .productList::-webkit-scrollbar {
      display: none;
    }
  .productList {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 660px;
    .product-li {
      margin: 20px;
      width: 180px;
      height: 180px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      position: relative;
      &:hover {
        box-shadow: 0px 0px 6px rgb(163, 165, 166);
        transition: 0.3s;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      .li-msg {
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0px;
        border-radius: 0px 0px 10px 10px;
        .msg-name {
          font-weight: 600;
          padding-top: 5px;
          padding-left: 10px;
        }
        .msg-content {
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 14px;
          padding-top: 5px;
          div:nth-child(1) {
            font-weight: 600;
          }
          div:nth-child(3) {
            button {
              width: 50px;
              height: 25px;
              border: none;
              background-color: #409eff;
              color: #fff;
              border-radius: 5px;
              &:hover {
                background-color: #66b1ff;
                transition: 0.5s;
              }
              &:active {
                background-color: #3a8ee6;
              }
            }
          }
        }
      }
    }
  }
  .paging {
    position: fixed;
    bottom: 30px;
  }
  //   弹窗
  .addProduct {
    .el-dialog {
      width: 500px;
    }
    .el-dialog__body {
      .el-form {
        .el-form-item {
          .el-form-item__content {
            .el-upload-list {
              width: 100px;
              position: absolute;
              left: 220px;
              top: -40px;
              .el-upload-list__item {
                .el-upload-list__item-name {
                  display: none;
                }
              }
            }
            .el-input {
              width: 300px;
            }
          }
        }
      }
    }
  }
}
</style>
