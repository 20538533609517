<template>
  <div>
    <el-upload
      :action="dataObj.host"
      :data="dataObj"
      list-type="picture"
      :multiple="false"
      :show-file-list="showFileList"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview"
    >
      <el-button size="small" type="primary" id="uploadPictures"
        >点击上传</el-button
      >
      <!-- <div slot="tip" class="el-upload__tip">
        只能上传jpg/png文件,且不超过5M
      </div> -->
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="fileList[0].url" />
    </el-dialog>
  </div>
</template>

<script>
import http from "../http/index.js";
export default {
  name: "SingleUpload",
  props: {
    value: String,
  },
  data() {
    return {
      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: "",
      },
      dialogVisible: false,
    };
  },
  computed: {
    imageUrl() {
      return this.value;
    },
    imageName() {
      if (this.value != null && this.value !== "") {
        // return this.value.substr(this.value.lastIndexOf("/") + 1);
        return this.value.split("/").pop();
      } else {
        return null;
      }
    },
    fileList() {
      return [
        {
          name: this.imageName,
          url: this.imageUrl,
        },
      ];
    },
    showFileList: {
      get: function () {
        return (
          this.value !== null && this.value !== "" && this.value !== undefined
        );
      },
      set: function (newValue) {
        console.log(newValue);
      },
    },
  },
  methods: {
    emitInput(val) {
      this.$emit("input", val);
    },
    handleRemove() {
      this.emitInput("");
    },
    handlePreview() {
      this.dialogVisible = true;
    },
    getUUID() {
      return "xxxx-xxxx-4xxx-yxxx-xxxxx".replace(/[xy]/g, (c) => {
        return (
          c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8"
        ).toString(16);
      });
    },
    beforeUpload() {
      const _self = this;
      return new Promise((resolve, reject) => {
        // 前后端提交post异步请求获取签名信息
        http({
          url: "/oss/policy?tenantNum=" + 123,
          methods: "GET",
        })
          .then((response) => {
            _self.dataObj.policy = response.data.data.policy;
            _self.dataObj.signature = response.data.data.signature;
            _self.dataObj.ossaccessKeyId = response.data.data.accessid;
            _self.dataObj.key = response.data.data.dir + this.getUUID();
            _self.dataObj.dir = response.data.data.dir;
            _self.dataObj.host = response.data.data.host;
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      });
    },
    handleUploadSuccess(res, file) {
      //   console.log(res);
      //   console.log(file);
      this.$message({
        message: "图片上传成功",
        type: "success",
      });
      this.showFileList = true;
      this.fileList.pop();
      this.fileList.push({
        name: file.name,
        url:
          "https://wx1.shiguangyimei.com/" +
          this.dataObj.key.replace("${filename}", file.name),
      });
      this.emitInput(this.fileList[0].url);
    },
  },
};
</script>
<style lang="scss">
#uploadPictures {
  // position: absolute;
  // left: 60%;
  // top: -10%;
}
</style>
