import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import( '../views/User.vue'),
  },
  {
    path: '/addition',
    name: 'Addition',
    component: () => import( '../views/Addition.vue')
  },
  {
    path: '/parti',
    name: 'parti',
    component: () => import( '../views/Particulars.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import( '../views/ManageInventory/product.vue')
  },
  {
    path: '/project',
    name: 'project',
    component: () => import( '../views/ManageInventory/project.vue')
  },
  {
    path: '/photo',
    name: 'photo',
    component: () => import( '../views/PhotoManager/PhotoPro.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '../views/Jurisdiction/Register.vue')
  },
  {
    path: '/sort',
    name: 'sort',
    component: () => import( '../views/Jurisdiction/SortManagement.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path!='/' && !localStorage.getItem('satoken')){
    next("/")
  }
  next()
  console.log(from);
})
export default router
