import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
// axios.defaults.withCredentials = true
// axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8"
import qs from "qs"
// import dayjs from "dayjs"
// Vue.prototype.dayjs = dayjs;
import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker)
Vue.use(VueAxios, axios)
Vue.use(qs)
Vue.use(ElementUI);

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
